import { metadataConstants } from "../constants/metadata";

const initialState = {
    statusCode: null,
    data: {},
    hashes: {},
};

export default function metadata(state = initialState, action) {
    switch (action.type) {
        case metadataConstants.GET_METADATA_SUCCESS: {
            const { statusCode, data: incomingData = {} } = action.data || {};
            const { hashes: incomingHashes = {}, ...rest } = incomingData;

            return {
                ...state,

                statusCode: statusCode ?? state.statusCode,
                data: {
                    ...state.data,
                    ...rest, // everything except `hashes`
                },
                hashes: {
                    ...state.hashes,
                    ...incomingHashes, // merge new hashes
                },
            };
        }

        case metadataConstants.UPDATE_METADATA_PROPERTY: {
            const { key, data: newData } = action;

            const { statusCode, data: nestedData = {} } = newData || {};

            const { hashes: incomingHashes = {}, ...rest } = nestedData;

            return {
                ...state,
                statusCode: statusCode ?? state.statusCode,
                data: {
                    ...state.data,
                    // Overwrite only the requested property
                    [key]: rest[key],
                },
                hashes: {
                    ...state.hashes,
                    // If `hashes: { geos: "..." }`, store that at state.hashes.geos
                    [key]: incomingHashes[key],
                },
            };
        }

        case metadataConstants.GET_METADATA_FAILURE: {
            return {
                ...state,
                error: action.error,
            };
        }

        default:
            return state;
    }
}
