import { _ } from "V3";
import MuiAutoComplete from "@material-ui/core/Autocomplete";
import TextField from "@material-ui/core/TextField";

export const V3AutoCompleteWithSelectAllFeature = ({
    name,
    value,
    options,
    layoutAssets,
    onChange,
    fullWidth = true,
    variant = "outlined",
    size = "small",
    required = false,
    label = "labelValue",
    error = false,
    shortCutForOptions,
    getOptionLabel = (option) => option,
    isOptionEqualToValue = (option, value) => option === value,
    ...props
}) => {
    const generateOptions = (() => {
        let generatedOptions = [];
        if (shortCutForOptions) {
            // SHORTCUTs keys has to be same with incoming options.
            // {id: 64, country: 'Albania', code: 'AL'}
            generatedOptions = [...shortCutForOptions];
        }

        if (typeof options === "function") {
            generatedOptions = [...generatedOptions, ...options(layoutAssets)];
        } else {
            generatedOptions = [...generatedOptions, ...(options || [])];
        }
        return generatedOptions;
    })();
    const selectionValue = value?.value || (props.multiple ? [] : null);
    const inputValue = value?.inputValue || "";

    const updateValues = (props) => {
        const { selection, input } = props || {};
        if (selection && shortCutForOptions) {
            const incomingSelectionLabels = selection.map((a) => getOptionLabel(a));
            const shortCutLabels = shortCutForOptions.map((a) => getOptionLabel(a));
            const jointItems = _.intersection(incomingSelectionLabels, shortCutLabels);
            if (jointItems.length > 0) {
                // Shortcut is arrived. Handled.
                const findShortcutFn = shortCutForOptions.filter((a) => {
                    const aLabel = getOptionLabel(a);
                    if (aLabel === jointItems[0]) {
                        return a;
                    }
                    return undefined;
                });
                const disableClearSelection = findShortcutFn[0].clearSelection;
                const shortCutsFunction = findShortcutFn[0].fn;
                shortCutsFunction({
                    onChange,
                    name,
                    selection: disableClearSelection
                        ? selection
                        : selection.filter((a) => {
                              const aLabel = getOptionLabel(a);
                              if (aLabel !== jointItems[0]) {
                                  return a;
                              } else return null;
                          }),
                });
                return;
            }
        }
        if (selection !== undefined) onChange({ value: selection, inputValue: inputValue }, name);
        if (input !== undefined) onChange({ value: selectionValue, inputValue: input }, name);
    };

    /**
     * Return
     */
    return (
        <MuiAutoComplete
            value={selectionValue}
            onChange={(e, newValue) => {
                updateValues({ selection: newValue });
            }}
            inputValue={inputValue}
            onInputChange={(e, newInputValue) => {
                updateValues({ input: newInputValue });
            }}
            // options functions
            options={generateOptions}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            filterSelectedOptions
            //
            fullWidth={fullWidth}
            variant={variant}
            size={size}
            //
            renderInput={(params) => (
                <TextField
                    {...params}
                    required={required}
                    label={label}
                    variant="outlined"
                    error={error}
                    autoComplete="new"
                />
            )}
            //
            {...props}
        />
    );
};
