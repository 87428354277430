import { Spacer, FormCreator, V3TextField, _, V3AutoComplete, V3BooToggle, TableCreator } from "V3";
import { rules } from "../../Functions/validationRules.js";
import ReplayIcon from "@material-ui/icons/Replay";

/**
 * mergeSections
 */
export const mergeSections = (obj) => {
    const output = { assets: obj.assets };
    _.forOwn(obj.allSections, (val, key) => (output[key] = val));
    return _.cloneDeep(output);
};

/**
 * cleanUpIsolated
 */
export const cleanUpStateIsolated = ({ setPageState, pageSettings }) => {
    setPageState(mergeSections(pageSettings));
};
export const cleanUpIsolated = (setPageState, setIsLoading, setSnackBarState, pageSettings) => {
    setIsLoading(false);
    setSnackBarState();
    cleanUpStateIsolated({ setPageState, pageSettings });
};

/**
 * componentSelector
 */
export const componentSelector = (componentName) => {
    switch (componentName) {
        case "FormCreator":
            return FormCreator;
        case "TableCreator":
            return TableCreator;
        case "V3TextField":
            return V3TextField;
        case "V3AutoComplete":
            return V3AutoComplete;
        case "V3BooToggle":
            return V3BooToggle;
        case "hasToBeFilled":
            return rules.hasToBeFilled;
        case "hasToBeFilledForMultipleV3AutoComplete":
            return rules.hasToBeFilledForMultipleV3AutoComplete;
        case "hasToBeANumber":
            return rules.hasToBeANumber;
        default:
            return Spacer;
    }
};

/**
 * openSnackBarIsolated & closeSnackBarIsolated
 */
export const openSnackBarIsolated = (msg, type, persistent = false, setSnackBarState) => {
    /*
    SOME snackbar TESTs
    openSnackBar("msg1", "error")
    openSnackBar("msg2", "success")
    openSnackBar("msg3")
    */
    setSnackBarState({
        open: true,
        message: msg,
        type: type,
        persistent: persistent,
    });
};
export const closeSnackBarIsolated = (snackBarState, setSnackBarState) => {
    setSnackBarState({ ...snackBarState, open: false });
};

/**
 * validateAndHandleIsolated
 */
export const validateAndHandleIsolated = (p) => {
    const { newValue, fieldName, fieldState, setPageState, sectionName, pageState, dynamicVariables } = p || {};
    const { dontSendToState, onChange } = p || {};
    const { hide } = fieldState || {};
    const isHidden = !hide ? false : typeof hide === "boolean" ? hide : hide(dynamicVariables);
    let isValid = true;
    let isChanged = true;
    let errorList = [];
    const validationRules = fieldState.validationRules;

    /**
     * Check Validation
     */
    if (!isHidden && validationRules) {
        _.forEach(validationRules, (funcName) => {
            const func = componentSelector(funcName);
            const errorResponse = func(newValue);
            if (!errorResponse.status) {
                isValid = false;
                errorList.push(errorResponse.msg);
            }
        });
    }

    /**
     * Check isChanged
     */
    if (_.isEqual(newValue, fieldState.resetValue) || (newValue === "" && fieldState.resetValue === undefined))
        isChanged = false;

    /**
     * Return
     */

    if (dontSendToState) {
        return { currentValue: newValue, isValid, errorList, isChanged };
    } else {
        const fieldState = {
            ...pageState[sectionName].formCreatorFields[fieldName],
            currentValue: newValue,
            isValid,
            errorList,
            isChanged,
        };

        const { updatedData } = setPageState(fieldState, `${sectionName}.formCreatorFields.${fieldName}`);
        if (onChange) {
            onChange({
                fieldName,
                currentValue: newValue,
                fieldState,
                updatedData,
                pageState,
                setPageState,
            });
        }
    }
};

/**
 * validationCheckForAll
 */
export const validationCheckForAll = (p) => {
    const { layoutAssets } = p || {};
    const { sectionState, setPageState, sectionName, pageState, dynamicVariables } = layoutAssets || {};
    const newFormCreatorFields = _.cloneDeep(sectionState.formCreatorFields);
    _.forOwn(sectionState.formCreatorFields, (field, key) => {
        const result = validateAndHandleIsolated({
            newValue: field.currentValue,
            fieldName: key,
            fieldState: field,
            setPageState: setPageState,
            sectionName: sectionName,
            pageState: pageState,
            dontSendToState: true,
            dynamicVariables,
        });
        newFormCreatorFields[key] = {
            ...layoutAssets.sectionState.formCreatorFields[key],
            isValid: result.isValid,
            errorList: result.errorList,
            isChanged: result.isChanged,
        };
    });
    setPageState(newFormCreatorFields, `${sectionName}.formCreatorFields`);
};

/**
 * resetCurrentValues
 */
export const resetCurrentValues = (p) => {
    const fn = (p) => {
        const { sectionName, sectionState, setPageState, fieldName, pageState, dynamicVariables } = p || {};
        const { setIsRevenueDealFieldsHidden } = dynamicVariables;
        let newFormCreatorFields = _.cloneDeep(sectionState.formCreatorFields);
        _.forOwn(newFormCreatorFields, (val, key) => {
            const fieldState = sectionState.formCreatorFields[key];
            const newValue = fieldState.resetValue || undefined;
            const { isValid, errorList } = validateAndHandleIsolated({
                newValue,
                fieldName,
                fieldState,
                setPageState,
                sectionName,
                pageState,
                dontSendToState: true,
            });
            newFormCreatorFields[key].currentValue = newValue;
            newFormCreatorFields[key].isValid = isValid;
            newFormCreatorFields[key].errorList = errorList;
        });
        setPageState(newFormCreatorFields, `${sectionName}.formCreatorFields`);
        setIsRevenueDealFieldsHidden(true);
    };

    return { label: "Reset", hideLabel: false, icon: ReplayIcon, onClick: fn };
};

/**
 * checkIsSectionChangedAndValid
 */
export const checkIsSectionChangedAndValid = (p) => {
    const { sectionState, setIsSectionChanged, setIsSectionValid } = p || {};
    let newIsSectionChanged = false,
        newIsSectionValid = true;
    /**
     * Check isSectionChanged and isSectionValid
     */
    _.forOwn(sectionState?.formCreatorFields, (field, key) => {
        if (field.hide === true) return;
        if (field.isChanged) newIsSectionChanged = true;
        if (field.isValid === false) newIsSectionValid = false;
    });
    setIsSectionChanged(newIsSectionChanged);
    setIsSectionValid(newIsSectionValid);
};

/**
 * getMetadataGeosAsSorted
 */
const sortFn = (a, b, order = "asc") => {
    const valA = a.country || -1;
    const valB = b.country || -1;
    if (valA < valB) return order === "asc" ? -1 : 1;
    else if (valA > valB) return order === "asc" ? 1 : -1;
    return 0;
};
export const getMetadataGeosAsSorted = (p) => {
    const { metadata } = p || {};
    const order = "asc";
    return metadata?.data?.geos?.sort((a, b) => sortFn(a, b, order));
};
/**
 * getMetadataVerticalsAsSorted
 */
export const getMetadataVerticalsAsSorted = (p) => {
    const { metadata } = p || {};
    return metadata?.data?.verticalTags?.sort(sortFn);
};
/**
 * getMetadataVerticalsAsSorted
 */
export const getMetadataPlatformsAsSorted = (p) => {
    const { metadata } = p || {};
    return metadata?.data?.platformTags?.sort(sortFn);
};
/**
 * getMetadataRevenueTypesAsSorted
 */
export const getMetadataRevenueTypeAsSorted = (p) => {
    const { metadata, isCPL } = p || {};
    if (isCPL) {
        return metadata?.data?.publisherDealRevenueTypes?.filter((o) => o.id === "usd");
    }
    return metadata?.data?.publisherDealRevenueTypes?.sort(sortFn);
};
/**
 * getMetadataIntegrationsAsSorted
 */
export const getMetadataIntegrationsAsSorted = (p) => {
    const { metadata } = p || {};
    return metadata?.data?.integrations?.sort(sortFn);
};
/**
 * getMetadataPublisherDealRevenueTypes
 */
export const getMetadataPublisherDealRevenueTypes = (p) => {
    const { metadata } = p || {};
    return metadata?.data?.publisherDealRevenueTypes;
};

/** Get Metadata  */
export const getMetadataPublishersAsSorted = (p) => {
    const { metadata } = p || {};
    return metadata?.data?.publishers?.sort(sortFn);
};
/**
 * componentProps for metadata GEO
 */
export const componentPropsForGeos = {
    required: true,
    label: "Country",
    disableClearable: true,
    options: (layoutAssets) => getMetadataGeosAsSorted(layoutAssets),
    getOptionLabel: (option) => option.country,
};
/**
 * componentProps for metadata INTEGRATIONS
 */
export const componentPropsForIntegrations = {
    required: true,
    label: "Integration",
    disableClearable: true,
    options: (layoutAssets) => getMetadataIntegrationsAsSorted(layoutAssets),
    getOptionLabel: (option) => option.name,
};

/**
 * getCountryMeta
 */
export const getCountryMeta = (p) => {
    const { id, metadata } = p || {};
    const metadataValues = metadata?.data?.geos || [];
    return metadataValues?.find((item) => item.id === id);
};
/**
 * getIntegrationsMeta
 */
export const getIntegrationsMeta = (p) => {
    const { id, metadata } = p || {};
    const metadataValues = metadata?.data?.integrations || [];
    return metadataValues?.find((item) => item.id === id);
};
