import { useDispatch } from "react-redux";
import { metadata as metadataActions } from "store/actions/metadata";

export function useGetMetadata() {
    const dispatch = useDispatch();

    const getMetadata = (propertyNames) => {
        return dispatch(metadataActions.getMetaData(propertyNames));
    };

    return getMetadata;
}
